import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import { HTMLAttributes, ReactNode, useContext } from "react";
import ModalContext from "../../../../contexts/modal-context";
import Button from "../../../UI/Button/Button";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {
    status: "success" | "warning" | "error" | "info";
    message: ReactNode;
    onConfirm?: () => void;
    link?: ReactNode;
    confirmButtonText?: string;
    closeButtonText?: string;
    title?: string;
}

const AlertOverlay = ({
    status,
    message,
    onConfirm,
    link,
    confirmButtonText,
    closeButtonText,
    id,
    title,
}: Props) => {
    const { t } = useTranslation();
    const { closeModal } = useContext(ModalContext);

    let localTitle = title || t("modal.success");
    let titleClass = "text-success";
    if (status === "error") {
        localTitle = title || t("modal.error");
        titleClass = "text-danger";
    } else if (status === "warning") {
        localTitle = title || t("modal.warning");
        titleClass = "text-dark";
    } else if (status === "info") {
        localTitle = title || t("modal.info");
        titleClass = "text-dark";
    }

    const confirmHandler = () => {
        closeModal();
        onConfirm!();
    };

    return (
        <ModalOverlay id={id}>
            <h6
                id="68033820-9846-40da-9db1-33624ec5decd"
                className={`text-capitalize ${titleClass}`}
            >
                {localTitle}
            </h6>
            <hr id="e7302ec7-850f-4871-8db6-aebe8a95c37b" />
            <p
                id="ff6b566c-c5f3-4d24-9a26-b553729d0eee"
                className="form-text text-black"
                style={{
                    whiteSpace: "pre-line",
                }}
            >
                {message}
            </p>
            <hr id="9cd4c67a-90b7-4d95-9f2d-7556e05ed070" />
            <div
                id="f8b36029-6bac-4159-950b-4c820c7d82ca"
                className="d-flex justify-content-end gap-2"
            >
                <Button
                    id="28cae0ba-0a2c-465a-8b0b-adae3a70bedf"
                    className="btn btn-sm btn-dark"
                    onClick={closeModal}
                >
                    {closeButtonText || t("modal.close")}
                </Button>
                {link}
                {onConfirm && (
                    <Button
                        id="c203f6b7-5f93-48d3-a997-2393bf77090d"
                        className="btn btn-sm btn-primary"
                        onClick={confirmHandler}
                    >
                        {confirmButtonText || t("modal.confirm")}
                    </Button>
                )}
            </div>
        </ModalOverlay>
    );
};

export default AlertOverlay;
