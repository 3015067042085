import PaymentMethodsListItem from "./PaymentMethodsListItem/PaymentMethodsListItem";
import PaymentMethod from "../../../../../models/PaymentMethod";
import React, { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {
    paymentMethods: PaymentMethod[];
    setPaymentMethodAsDefault: (id: string, newPaymentMethod: boolean) => void;
    deletePaymentMethod: (id: string) => void;
}

const PaymentMethodsList = ({
    id,
    paymentMethods,
    setPaymentMethodAsDefault,
    deletePaymentMethod,
}: Props) => {
    const { t } = useTranslation();
    const renderPaymentMethodsList = paymentMethods.map(
        (item: PaymentMethod) => (
            <PaymentMethodsListItem
                id={`payment-method-${item.id}`}
                key={item.id}
                paymentMethod={item}
                setAsDefault={setPaymentMethodAsDefault}
                deletePaymentMethod={deletePaymentMethod}
            />
        )
    );

    return (
        <div id={id}>
            <h6 id="e6824eab-fb4b-4057-b201-4aa0cba4c6e9">
                {t(
                    "settingsPage.paymentMethodsSettings.paymentMethodsList.paymentMethods"
                )}
            </h6>
            <hr id="90a96359-9cb9-44de-bb51-2e8a449fe1e8" />
            <div
                id="c59d31e9-d2fc-4aed-9e34-ebeae264402b"
                className="d-flex flex-column gap-1"
            >
                {renderPaymentMethodsList.length > 0 ? (
                    renderPaymentMethodsList
                ) : (
                    <span
                        id="61da3a34-86c7-427d-abcf-085756542c1a"
                        className="form-text"
                    >
                        {t(
                            "settingsPage.paymentMethodsSettings.paymentMethodsList.noPaymentMethods"
                        )}
                    </span>
                )}
            </div>
        </div>
    );
};

export default PaymentMethodsList;
