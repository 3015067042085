import ProfileLink from "../../ProfileLink/ProfileLink";
import { TbHeartHandshake } from "react-icons/tb";
import { MdStarBorder } from "react-icons/md";
import { BsQuestionCircle } from "react-icons/bs";
import React, {
    ForwardedRef,
    HTMLAttributes,
    useContext,
    useState,
} from "react";
import ModalContext from "../../../contexts/modal-context";
import Petition, { PetitionStatus } from "../../../models/Petition";
import PetitionSupportersListOverlay from "../../Modal/overlays/PetitionSupportersListOverlay/PetitionSupportersListOverlay";
import SupportPetitionOverlay from "../../Modal/overlays/SupportPetitionOverlay/SupportPetitionOverlay";
import { Link, useNavigate } from "react-router-dom";
import Countdown from "../../UI/Countdown/Countdown";
import PetitionAddressee, {
    PetitionAddresseeStatus,
} from "../../../models/PetitionAddressee";
import {
    FailureResponse as UpdatePetitionAddresseeFailureResponse,
    updatePetitionAddressee as api_updatePetitionAddressee,
} from "../../../api/petitions/updatePetitionAddressee";
import {
    FailureResponse as UpdatePetitionFailureResponse,
    updatePetition as api_updatePetition,
} from "../../../api/petitions/updatePetition";
import AlertOverlay from "../../Modal/overlays/AlertOverlay/AlertOverlay";
import PetitionActionButtons from "./PetitionActionButtons/PetitionActionButtons";
import AddPetitionEndResultOverlay from "../../Modal/overlays/AddPetitionEndResultOverlay/AddPetitionEndResultOverlay";
import RatePetitionEndResultOverlay from "../../Modal/overlays/RatePetitionEndResultOverlay/RatePetitionEndResultOverlay";
import PetitionEndResultRatersListOverlay from "../../Modal/overlays/PetitionEndResultRatersListOverlay/PetitionEndResultRatersListOverlay";
import AuthContext from "../../../contexts/auth-context";
import {
    FailureResponse,
    supportPetition as api_supportPetition,
} from "../../../api/petitions/supportPetition";
import { ApiErrorCode } from "../../../constants";
import EmailNotVerifiedErrorOverlay from "../../Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import { useTranslation } from "react-i18next";
import { getRemainingTimeFormatted } from "../../../utils/helpers";
import RejectPetitionOverlay from "../../Modal/overlays/RejectPetitionOverlay/RejectPetitionOverlay";

interface Props extends HTMLAttributes<any> {
    petition: Petition;
}

const PetitionsListItem = React.forwardRef(
    (
        { id, petition: initialPetition }: Props,
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        const { t } = useTranslation();
        const { showModal, closeModal } = useContext(ModalContext);
        const { user } = useContext(AuthContext);
        const [petition, setPetition] = useState(initialPetition);
        const [acceptLoading, setAcceptLoading] = useState(false);
        const [rejectLoading, setRejectLoading] = useState(false);
        const [markAsFulfilledLoading, setMarkAsFulfilledLoading] =
            useState(false);
        const [cancelLoading, setCancelLoading] = useState(false);
        const [supportLoading, setSupportLoading] = useState(false);
        const navigate = useNavigate();

        const showSupportersHandler = () => {
            showModal(
                <PetitionSupportersListOverlay
                    id="68939145-6979-4199-84e7-d0e94c0751cf"
                    petition={petition}
                />
            );
        };

        const supportPetitionWithPledgingHandler = (
            updatedPetition: Petition
        ) => {
            setPetition(updatedPetition);
            showModal(
                <AlertOverlay
                    id="e49e487b-9e93-4ed9-b881-4e37b86622d3"
                    status="success"
                    message={t("petition.supportPetitionSuccess")}
                />
            );
        };

        const supportPetitionWithoutPledging = async () => {
            setSupportLoading(true);
            try {
                const response = await api_supportPetition(petition.uuid, 0);
                setPetition(response);
                showModal(
                    <AlertOverlay
                        id="a54eb1f5-e373-4f3d-9276-d418a9547175"
                        status="success"
                        message={t("petition.supportPetitionSuccess")}
                    />
                );
            } catch (error) {
                const { errors, status_code, code } = error as FailureResponse;
                if (errors) {
                    if (status_code === 403) {
                        if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                            showModal(
                                <EmailNotVerifiedErrorOverlay
                                    id="5971ea50-5398-4cc5-be94-d7f0bdace7ee"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                        } else
                            showModal(
                                <AlertOverlay
                                    id="34d6c69d-01ed-4f8a-830b-82891cf16ee1"
                                    status="error"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                    } else {
                        if (errors.non_field_errors)
                            showModal(
                                <AlertOverlay
                                    id="17a974e7-fba2-4fb7-94ce-c5f0092c8df2"
                                    status="error"
                                    message={errors.non_field_errors[0]}
                                />
                            );
                        if (errors.error)
                            showModal(
                                <AlertOverlay
                                    id="5f0cd8b9-26cf-47bd-8a3e-e99f6d997ff5"
                                    status="error"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                    }
                }
            }
            setSupportLoading(false);
        };

        const supportPetition = async () => {
            if (user) {
                if (petition.accepts_pledges) {
                    if (user.account!.payment_settings.currency)
                        showModal(
                            <SupportPetitionOverlay
                                id="a0b31f58-d8f2-4bdf-8063-26a3680fdb5b"
                                petition={petition}
                                onSupport={supportPetitionWithPledgingHandler}
                            />
                        );
                    else
                        showModal(
                            <AlertOverlay
                                id="90912b3c-4a5b-4d2a-a979-82a6b43c2296"
                                status="error"
                                message={t("petition.pleaseSetCurrency")}
                                link={
                                    <Link
                                        id="petitions-list-item-alert-overlay-to-settings-account"
                                        to="/settings/account"
                                        className="btn btn-sm btn-primary"
                                    >
                                        {t("petition.setCurrency")}
                                    </Link>
                                }
                            />
                        );
                } else await supportPetitionWithoutPledging();
            } else {
                localStorage.setItem("referrer", window.location.href);
                navigate("/signup");
                // showModal(
                //     <ModalOverlay id="62bc6f85-fc00-4e76-8cb9-0ca782958b71">
                //         <div className="my-2">
                //             <SignupForm id={SignUpFormID.SIGNUP_FORM} />
                //         </div>
                //     </ModalOverlay>
                // );
            }
        };

        const showPetitionEndResultRatersHandler = () => {
            showModal(
                <PetitionEndResultRatersListOverlay
                    id="3f0c2d32-d505-4bdc-ac3f-e52df6c05df8"
                    petition={petition}
                />
            );
        };

        const ratePetitionEndResultHandler = (updatedPetition: Petition) => {
            setPetition(updatedPetition);
            closeModal();
        };

        const ratePetition = () => {
            showModal(
                <RatePetitionEndResultOverlay
                    id="93ad9a89-d7e9-40e6-9c7d-6c2a7c4b2f60"
                    petition={petition}
                    onRate={ratePetitionEndResultHandler}
                />
            );
        };

        const updatePetitionAddresseeStatus = async (
            addressee_uuid: string,
            status: PetitionAddresseeStatus,
            rejection_reason?: string
        ) => {
            if (status === PetitionAddresseeStatus.ACCEPTED)
                setAcceptLoading(true);
            else setRejectLoading(true);
            try {
                const response = await api_updatePetitionAddressee(
                    petition.uuid,
                    addressee_uuid,
                    status,
                    rejection_reason
                );
                setPetition(response);
            } catch (error) {
                const { errors, code } =
                    error as UpdatePetitionAddresseeFailureResponse;
                if (errors) {
                    if (errors.rejection_reason) {
                        showModal(
                            <AlertOverlay
                                id="8e431904-1b87-470d-9c4f-d2e37f9d8239"
                                status="error"
                                message={errors.rejection_reason[0]}
                            />
                        );
                    } else if (code === ApiErrorCode.NO_PAYOUT_METHOD) {
                        showModal(
                            <AlertOverlay
                                id="04f9b8d2-abbc-45e0-9c6c-c33424bf7e0b"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                                link={
                                    <Link
                                        // id="petitions-list-item-alert-overlay-to-settings-payout"
                                        id="f8d83662-b7de-4205-a8cf-6e33a86769c8"
                                        to="/settings/payout"
                                        className="btn btn-sm btn-primary"
                                    >
                                        {t(
                                            "settingsPage.payoutSettings.payoutMethodSettings.addPayoutMethod"
                                        )}
                                    </Link>
                                }
                            />
                        );
                    } else {
                        showModal(
                            <AlertOverlay
                                id="129bd33f-92c7-497e-bf09-4a5783742abd"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    }
                }
            }
            setAcceptLoading(false);
            setRejectLoading(false);
        };

        const updatePetitionStatus = async (status: PetitionStatus) => {
            if (status === PetitionStatus.FULFILLED)
                setMarkAsFulfilledLoading(true);
            else setCancelLoading(true);

            try {
                const response = await api_updatePetition(
                    petition.uuid,
                    status
                );
                setPetition(response);
            } catch (error) {
                const { errors } = error as UpdatePetitionFailureResponse;
                if (errors)
                    showModal(
                        <AlertOverlay
                            id="0a032883-ee63-4bcd-be9c-a40b545d02a5"
                            status="error"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
            }

            setMarkAsFulfilledLoading(false);
            setCancelLoading(false);
        };

        const statusName: string = t(`petition.statuses.${petition.status}`);
        let statusClass = "";
        switch (petition.status) {
            case PetitionStatus.ONGOING:
                statusClass = "warning";
                break;
            case PetitionStatus.CANCELED:
                statusClass = "light";
                break;
            case PetitionStatus.REJECTED:
                statusClass = "danger";
                break;
            case PetitionStatus.EXPIRED:
                statusClass = "secondary";
                break;
            case PetitionStatus.ACCEPTED:
                statusClass = "info";
                break;
            case PetitionStatus.NOT_FULFILLED:
                statusClass = "dark";
                break;
            case PetitionStatus.FULFILLED:
                statusClass = "success";
                break;
        }

        let usd_pledged_amount = 0;
        for (let item of petition.total_pledged_amount_by_currency) {
            if (item.currency.toUpperCase() === "USD")
                usd_pledged_amount = item.total_amount;
        }

        return (
            <div id={id} key={petition.uuid} ref={ref}>
                <div
                    id="f8c4c0d5-ec5c-4f84-b520-95cf459923dc"
                    className={`d-flex flex-column border-top border-start border-end gap-3 
                p-3 rounded-top`}
                >
                    <div
                        id="14357a6f-9e22-4c4a-82e9-196bdc65b559"
                        className="d-flex flex-column border-bottom gap-2 pb-3"
                    >
                        <h6
                            id="f4f93e39-f9f5-4b9a-a573-83f2e06abe0c"
                            className="card-title"
                        >
                            {petition.title}
                        </h6>
                        <p
                            id="f0ab0cab-99d6-490c-9642-85d68a4a2eea"
                            className="card-text form-text"
                            style={{ whiteSpace: "pre-line" }}
                        >
                            {petition.description}
                        </p>
                    </div>
                    <div
                        id="61d46911-9ddb-4d56-af3e-ee6a612aac5e"
                        className="d-flex align-items-center justify-content-between"
                    >
                        {petition.addressees &&
                        petition.addressees.length > 0 ? (
                            <ProfileLink
                                id={`petition-addressee-${petition.addressees[0].user.uuid}`}
                                user={petition.addressees[0].user}
                            />
                        ) : (
                            <div id="39fcd36f-bd5a-4dd6-8a11-2684f311dd66" />
                        )}
                        <div
                            id="072145d5-3900-43e5-b95f-a0f1dccc27ab"
                            className="d-flex flex-column gap-1 flex-sm-row align-items-center"
                        >
                            {petition.end_result &&
                                petition.end_result.average_rate && (
                                    <button
                                        id="a41ccbb2-d9c4-4134-9755-dfcb30c5805c"
                                        className="btn btn-sm btn-link text-decoration-none text-dark"
                                        onClick={
                                            showPetitionEndResultRatersHandler
                                        }
                                        title={t("petition.buttons.raters")}
                                    >
                                        <MdStarBorder
                                            id="a41ccbb2-d9c4-4134-9755-dfcb30c5805c-icon"
                                            className="mb-1"
                                        />{" "}
                                        {petition.end_result.average_rate}
                                    </button>
                                )}
                            <button
                                id="647eb457-d1c2-432f-bff5-b5294d056ed1"
                                className="btn btn-sm btn-link text-decoration-none text-dark"
                                onClick={showSupportersHandler}
                                title={t("petition.buttons.supporters")}
                            >
                                <TbHeartHandshake
                                    id="647eb457-d1c2-432f-bff5-b5294d056ed1-icon"
                                    className="mb-1"
                                />{" "}
                                {petition.supporters_count}
                            </button>
                            {petition.accepts_pledges && (
                                // <div className="dropup">
                                //     <button
                                //         className="btn btn-sm btn-link text-decoration-none text-black"
                                //         data-bs-toggle="dropdown"
                                //         aria-expanded="false"
                                //         title={t("petition.pledgedAmount")}
                                //     >
                                //         <BsCashCoin />
                                //     </button>
                                //     <ul className="dropdown-menu dropdown-menu-end p-2">
                                //         {petition.total_pledged_amount_by_currency.map(
                                //             (item: {
                                //                 currency: string;
                                //                 total_amount: number;
                                //             }) => (
                                //                 <li
                                //                     key={item.currency}
                                //                     className="form-text py-1 m-0"
                                //                 >
                                //                     {item.currency.toUpperCase()}{" "}
                                //                     {(
                                //                         item.total_amount / 100
                                //                     ).toFixed(2)}
                                //                 </li>
                                //             )
                                //         )}
                                //     </ul>
                                // </div>
                                <span
                                    id="156188d2-aac7-4092-91af-123a672ef3ca"
                                    className="form-text text-dark m-0"
                                >
                                    USD {(usd_pledged_amount / 100).toFixed(2)}
                                </span>
                            )}
                        </div>
                    </div>
                    <div
                        id="2d69d95b-4372-4c92-a56e-09c8b097776f"
                        className="d-flex flex-wrap align-items-center justify-content-between border-top pt-3"
                    >
                        {/*{petition.dt_next_status_update && (*/}
                        {/*    <Countdown*/}
                        {/*        deadline={new Date(petition.dt_next_status_update)}*/}
                        {/*    />*/}
                        {/*)}*/}
                        <PetitionActionButtons
                            id="2a1c3571-05b7-48a0-a7c2-4aa47cb767cf"
                            petition={petition}
                            onCancelButtonClick={() =>
                                showModal(
                                    <AlertOverlay
                                        id="3eaed611-359c-4c8e-b59f-a26b4798020f"
                                        status="warning"
                                        message={t("petition.sureWantToCancel")}
                                        onConfirm={() =>
                                            updatePetitionStatus(
                                                PetitionStatus.CANCELED
                                            )
                                        }
                                    />
                                )
                            }
                            onSupportButtonClick={supportPetition}
                            onAcceptButtonClick={(addressee_uuid: string) =>
                                showModal(
                                    <AlertOverlay
                                        id="d2617baf-30cf-4edb-8f5a-8851d1636544"
                                        status="warning"
                                        message={t(
                                            "petition.sureWantToAccept",
                                            {
                                                remaining_time:
                                                    getRemainingTimeFormatted(
                                                        new Date(
                                                            petition.dt_next_status_update
                                                        )
                                                    ),
                                            }
                                        )}
                                        onConfirm={() =>
                                            updatePetitionAddresseeStatus(
                                                addressee_uuid,
                                                PetitionAddresseeStatus.ACCEPTED
                                            )
                                        }
                                    />
                                )
                            }
                            onRejectButtonClick={(addressee_uuid: string) =>
                                showModal(
                                    <RejectPetitionOverlay
                                        id="c5fffc8d-dac7-4650-8cb5-3b3dfc6e9ab2"
                                        onConfirm={(
                                            rejection_reason: string | undefined
                                        ) =>
                                            updatePetitionAddresseeStatus(
                                                addressee_uuid,
                                                PetitionAddresseeStatus.REJECTED,
                                                rejection_reason
                                            )
                                        }
                                    />
                                )
                            }
                            onMarkAsFulfilledButtonClick={() =>
                                showModal(
                                    <AddPetitionEndResultOverlay
                                        id="101e50e1-8bf6-407b-9aa4-dc5abcf09210"
                                        petition={petition}
                                        onPetitionUpdate={setPetition}
                                    />
                                )
                            }
                            onRateEndResultButtonClick={ratePetition}
                            acceptLoading={acceptLoading}
                            rejectLoading={rejectLoading}
                            markAsFulfilledLoading={markAsFulfilledLoading}
                            cancelLoading={cancelLoading}
                            supportLoading={supportLoading}
                        />
                    </div>
                </div>
                <div
                    id="e1b62225-9d73-4e76-885c-0c526b018d4d"
                    className={`rounded-bottom bg-${statusClass}-subtle 
                form-text mt-0 py-1 border-start border-end border-bottom`}
                >
                    <div
                        id="c5dab44d-e1e4-4d15-ab3b-1b474ca3d712"
                        className={`d-flex justify-content-center gap-2 text-${statusClass}-emphasis `}
                    >
                        {statusName.toUpperCase()}
                        {petition.status === PetitionStatus.REJECTED && (
                            <div
                                id="63f9d096-fc26-49e4-9ca8-91e70e576521"
                                className="dropdown-center"
                            >
                                <button
                                    id="9e18a4dd-d58c-4984-ab7d-659330b26e2d"
                                    className="btn nav-link"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <BsQuestionCircle
                                        id="9e18a4dd-d58c-4984-ab7d-659330b26e2d-icon"
                                        style={{ marginBottom: "2px" }}
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title={t(
                                            "petition.viewRejectionReason"
                                        )}
                                    />
                                </button>
                                <ul
                                    id="7a58a52a-5cd2-400e-87b3-d6fe194e6c60"
                                    className="dropdown-menu"
                                >
                                    <h6
                                        id="134ccbf1-8a0b-43e6-b350-db7aa5aaf595"
                                        className="dropdown-header"
                                    >
                                        {t("petition.rejectionReason")}:
                                    </h6>
                                    {petition.addressees.map(
                                        (
                                            item: PetitionAddressee,
                                            index: number
                                        ) => {
                                            return (
                                                <span
                                                    id="9f88cee5-1acd-4afb-8430-0d4a3a7d2f12"
                                                    key={item.uuid}
                                                >
                                                    <div
                                                        id="f9b6eba3-cae6-4dc2-8ff8-8da96932e6b2"
                                                        className="form-text dropdown-item-text"
                                                        style={{
                                                            whiteSpace:
                                                                "pre-line",
                                                        }}
                                                    >
                                                        {item.rejection_reason}
                                                    </div>
                                                    {index <
                                                        petition.addressees
                                                            .length -
                                                            1 && (
                                                        <hr
                                                            id="ac4c2e49-711d-4709-ba84-f26066003894"
                                                            className="dropdown-divider"
                                                        />
                                                    )}
                                                </span>
                                            );
                                        }
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                    {petition.dt_next_status_update && (
                        <div
                            id="39370d04-1099-4fec-81c0-59487eaa2335"
                            className={`d-flex justify-content-center form-text mt-0 text-${statusClass}-emphasis `}
                        >
                            <Countdown
                                id="9b088bb7-52b1-4165-8314-04292aa62b74"
                                deadline={
                                    new Date(petition.dt_next_status_update)
                                }
                            />
                            <div
                                id="4225e914-2036-4a0c-aca3-ea1c71c184cb"
                                className="dropdown-center"
                            >
                                <button
                                    id="6b594a7e-2146-41b8-a335-c7450e863c10"
                                    className="btn nav-link"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <BsQuestionCircle
                                        id="6b594a7e-2146-41b8-a335-c7450e863c10-icon"
                                        style={{ marginBottom: "1px" }}
                                    />
                                </button>
                                <ul
                                    id="2037f2c7-aa87-4630-8139-e2c7060a8fe3"
                                    className="dropdown-menu"
                                >
                                    <span
                                        id="00e5bf0c-2299-493f-9d9d-9adfdf9370d7"
                                        className="form-text dropdown-item-text"
                                    >
                                        {petition.status ===
                                        PetitionStatus.ONGOING
                                            ? t(
                                                  "petition.remainingTimeToAcceptAndFulfill"
                                              )
                                            : t(
                                                  "petition.remainingTimeToFulfill"
                                              )}
                                    </span>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

export default PetitionsListItem;
