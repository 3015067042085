import if_amex from "../../../../../../images/if_amex.png";
import if_discover from "../../../../../../images/if_discover.png";
import if_jcb from "../../../../../../images/if_jcb.png";
import if_mastercard from "../../../../../../images/if_mastercard.png";
import if_visa from "../../../../../../images/if_visa.png";
import PaymentMethod from "../../../../../../models/PaymentMethod";
import Button from "../../../../../../components/UI/Button/Button";
import { AiOutlineDelete } from "react-icons/ai";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoCheckboxOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<any> {
    paymentMethod: PaymentMethod;
    setAsDefault: (id: string, newPaymentMethod: boolean) => void;
    deletePaymentMethod: (id: string) => void;
}

const PaymentMethodsListItem = ({
    id,
    paymentMethod,
    setAsDefault,
    deletePaymentMethod,
}: Props) => {
    const { t } = useTranslation();
    let brand_img;
    switch (paymentMethod.brand) {
        case "amex":
            brand_img = if_amex;
            break;
        case "discover":
            brand_img = if_discover;
            break;
        case "jcb":
            brand_img = if_jcb;
            break;
        case "mastercard":
            brand_img = if_mastercard;
            break;
        case "visa":
            brand_img = if_visa;
            break;
        default:
            brand_img = if_visa;
            break;
    }

    let expired_badge = <></>;
    let default_badge = <></>;
    if (paymentMethod.is_expired)
        expired_badge = (
            <span
                id="95cae8f5-26e7-4581-8bfb-02c2184b3f30"
                className="badge bg-danger-subtle text-danger-emphasis"
            >
                {t(
                    "settingsPage.paymentMethodsSettings.paymentMethodsList.paymentMethodsListItem.expired"
                )}
            </span>
        );
    if (paymentMethod.is_default)
        default_badge = (
            <span
                id="61f16922-ee51-4c89-ae97-cb2cfe7d7388"
                className="badge bg-secondary-subtle text-secondary-emphasis"
            >
                {t(
                    "settingsPage.paymentMethodsSettings.paymentMethodsList.paymentMethodsListItem.default"
                )}
            </span>
        );

    return (
        <div
            id={id}
            className="d-flex flex-wrap align-items-center justify-content-between border rounded px-2 py-1"
        >
            <div
                id="1d96cab0-c5de-4694-ab00-846cb83a6da4"
                className="d-flex gap-2 gap-lg-3 form-text align-items-center"
            >
                <img
                    id="0f2d501a-8319-4ad5-bbfb-dbb02caca498"
                    src={brand_img}
                    style={{ width: "2rem" }}
                    alt="brand"
                />
                <span id="e3872c16-7c77-44ed-93ac-67df12c26f56">
                    **** {paymentMethod.last4}
                </span>
                <span id="2a35cd0b-8048-47a4-90f9-9aa594b6285f">
                    {paymentMethod.exp_month < 10
                        ? `0${paymentMethod.exp_month}`
                        : paymentMethod.exp_month}{" "}
                    / {paymentMethod.exp_year.toString().slice(-2)}
                </span>
            </div>
            <div
                id="cdbf57f0-3d0c-48fc-86f1-5d8e64d44415"
                className="d-flex align-items-center gap-1"
            >
                {default_badge}
                {expired_badge}
                <div
                    id="00317adb-f7b3-4509-ab2e-d32ede814e2f"
                    className="dropdown"
                >
                    <button
                        id="2631f448-623e-413f-b003-f6b0a2bc0310"
                        className="btn btn-link text-dark"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <HiOutlineDotsHorizontal id="2631f448-623e-413f-b003-f6b0a2bc0310-icon" />
                    </button>
                    <ul
                        id="ad54cc48-89e1-409b-8e29-8c28a0e7ad86"
                        className="dropdown-menu"
                    >
                        {
                            <li id="5ec0f029-725c-4fa6-b5c5-491f1d5404d6">
                                <Button
                                    id="4a39e3a6-282f-45ed-a36c-67af2596b69a"
                                    className={`dropdown-item ${
                                        (paymentMethod.is_default ||
                                            paymentMethod.is_expired) &&
                                        "disabled"
                                    }`}
                                    onClick={() =>
                                        setAsDefault(paymentMethod.id, false)
                                    }
                                >
                                    <IoCheckboxOutline
                                        id="4a39e3a6-282f-45ed-a36c-67af2596b69a-icon"
                                        className="mb-1 me-2"
                                    />
                                    {t(
                                        "settingsPage.paymentMethodsSettings.paymentMethodsList.paymentMethodsListItem.setAsDefault"
                                    )}
                                </Button>
                            </li>
                        }
                        {
                            <li id="18f37b02-8eb2-4a5e-94bf-9b55b25a6048">
                                <Button
                                    id="32de2125-16d7-4b84-8775-45495dcee518"
                                    className={`dropdown-item ${
                                        paymentMethod.is_default && "disabled"
                                    }`}
                                    onClick={() =>
                                        deletePaymentMethod(paymentMethod.id)
                                    }
                                >
                                    <AiOutlineDelete
                                        id="32de2125-16d7-4b84-8775-45495dcee518-icon"
                                        className="mb-1 me-2"
                                    />
                                    {t(
                                        "settingsPage.paymentMethodsSettings.paymentMethodsList.paymentMethodsListItem.delete"
                                    )}
                                </Button>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default PaymentMethodsListItem;
