import React, { HTMLAttributes, ReactNode } from "react";
import ModalOverlay from "../../ModalOverlay/ModalOverlay";

interface Props extends HTMLAttributes<any> {
    message: ReactNode;
}

const LoadingOverlay = ({ id, message }: Props) => {
    return (
        <ModalOverlay id={id}>
            <div
                id="bffd6c2d-9253-483b-a213-61480c79a062"
                className="form-text text-black m-0 d-flex gap-3 align-items-center"
                style={{
                    whiteSpace: "pre-line",
                }}
            >
                <span
                    id="c127a6a9-3b8b-4f8a-b71e-799fe439d42a"
                    className="spinner-border spinner-border-sm"
                />
                {message}
            </div>
        </ModalOverlay>
    );
};

export default LoadingOverlay;
